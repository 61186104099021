import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { LexiconModule } from '@galaxy/lexicon';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyAvatarModule } from '@vendasta/galaxy/avatar';
import { GalaxyBadgeModule } from '@vendasta/galaxy/badge';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { CenterSelectorComponent } from './center-selector/center-selector.component';
import { AccordionComponent } from './core-components/accordion/accordion.component';
import { ButtonComponent } from './core-components/button/button.component';
import { IconButtonDirective } from './core-components/button/icon-button.directive';
import { CheckboxComponent } from './core-components/checkbox/checkbox.component';
import { ChipComponent } from './core-components/chip/chip.component';
import { InkBarComponent } from './core-components/ink-bar/ink-bar.component';
import { LoaderComponent } from './core-components/loader/loader.component';
import { ModalContentComponent } from './core-components/modal/modal-content.component';
import { ModalFooterComponent } from './core-components/modal/modal-footer.component';
import { ModalHeaderComponent } from './core-components/modal/modal-header.component';
import { ModalComponent } from './core-components/modal/modal.component';
import { ModalService } from './core-components/modal/modal.service';
import { PanelContentComponent } from './core-components/panel/panel-content.component';
import { PanelDescriptionComponent } from './core-components/panel/panel-description.component';
import { PanelHeaderComponent } from './core-components/panel/panel-header.component';
import { PanelTitleComponent } from './core-components/panel/panel-title.component';
import { PanelComponent } from './core-components/panel/panel.component';
import { SlideToggleComponent } from './core-components/slide-toggle/slide-toggle.component';
import { SnackbarComponent } from './core-components/snackbar/snackbar.component';
import { TabGroupComponent } from './core-components/tab/tab-group.component';
import { TabHeaderComponent } from './core-components/tab/tab-header.component';
import { TabComponent } from './core-components/tab/tab.component';
import { AtlasTooltipModule } from './core-components/tooltip/tooltip-module';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { SearchBoxComponent } from './form-controls/search-box/search-box.component';
import { ItemComponent } from './item/item.component';
import { AtlasLanguageSelectorComponent } from './language-selector/language-selector.component';
import { LanguageListItemComponent } from './language-selector/modal/language-list/language-list-item/language-list-item.component';
import { LanguageListComponent } from './language-selector/modal/language-list/language-list.component';
import { AtlasLanguageSelectorModalComponent } from './language-selector/modal/language-selector-modal.component';
import * as baseTranslation from './languages/en_devel.json';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { NavComponent } from './nav/nav.component';
import { FlyoutComponent } from './notifications/flyout/flyout.component';
import { NotificationComponent } from './notifications/notification/notification.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AccountsFormSettingsComponent } from './notifications/settings/accounts-form/accounts-form-settings.component';
import { AccountsFormComponent } from './notifications/settings/accounts-form/accounts-form.component';
import { SettingsFormComponent } from './notifications/settings/settings-form/settings-form.component';
import { SettingsComponent } from './notifications/settings/settings.component';
import { firebaseConfig } from './realtime/config';
import { FirestoreDB } from './realtime/firestore';
import { TitleComponent } from './title/title.component';
import { TopBarItemsComponent } from './top-bar/top-bar-items/top-bar-items.component';
import { AtlasTopBarComponent } from './top-bar/top-bar.component';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { AtlasUserLanguageModalComponent } from './user-language-modal/user-language-modal.component';
import { UserSwitcherModalComponent } from './user-switcher-modal/user-switcher-modal.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationComponent,
    FlyoutComponent,
    CenterSelectorComponent,
    MenuComponent,
    MenuItemComponent,
    TitleComponent,
    NavComponent,
    UserDropdownComponent,
    ItemComponent,
    AtlasTopBarComponent,
    ChipComponent,
    CheckboxComponent,
    LoaderComponent,
    IconButtonDirective,
    AtlasLanguageSelectorComponent,
    AtlasLanguageSelectorModalComponent,
    AtlasUserLanguageModalComponent,
    LanguageListComponent,
    LanguageListItemComponent,
    ButtonComponent,
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    SettingsComponent,
    SettingsFormComponent,
    AccountsFormComponent,
    AccountsFormSettingsComponent,
    PanelComponent,
    AccordionComponent,
    PanelHeaderComponent,
    PanelContentComponent,
    PanelTitleComponent,
    PanelDescriptionComponent,
    SnackbarComponent,
    TabComponent,
    TabGroupComponent,
    TabHeaderComponent,
    InkBarComponent,
    SlideToggleComponent,
    SearchBoxComponent,
    EmailVerificationComponent,
    UserSwitcherModalComponent,
    TopBarItemsComponent,
  ],
  exports: [
    NavComponent,
    ItemComponent,
    MenuComponent,
    MenuItemComponent,
    TitleComponent,
    AtlasLanguageSelectorComponent,
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    SettingsComponent,
    TopBarItemsComponent,
  ],
  providers: [
    FirestoreDB,
    ModalService,
    provideFirebaseApp(() => initializeApp(firebaseConfig, 'notifications')),
    provideFirestore(() => getFirestore(getApp('notifications'))),
  ],
  imports: [
    CommonModule,
    RouterModule,
    AtlasTooltipModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    GalaxyAlertModule,
    GalaxyEmptyStateModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    GalaxyButtonLoadingIndicatorModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    LexiconModule.forChild({
      componentName: 'common/atlas',
      baseTranslation,
    }),
    MatDialogModule,
    GalaxyPipesModule,
    GalaxyBadgeModule,
    GalaxyAvatarModule,
  ],
})
export class AtlasModule {}
