import { HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  AtlasApiService,
  CenterNavigationItem,
  GetDataRequest,
  GetDataResponse,
  LanguagesApiService,
  Theming,
  UITheme,
  UserNavigationItem,
  UserSwitcherData,
} from '@vendasta/atlas';
import { LegacyNotificationPreferenceService } from '@vendasta/notifications-sdk';
import {
  catchError,
  combineLatest,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import { AtlasConfigService, Config } from './config.service';
import { Language } from './language';

interface CachedGetDataResponse {
  locationId: string;
  partnerId: string;
  marketId: string;
  response: GetDataResponse;
}

@Injectable({ providedIn: 'root' })
export class AtlasDataService {
  private readonly navigationData$: Observable<GetDataResponse>;
  public readonly centerData$: Observable<CenterNavigationItem[]>;
  public readonly userData$: Observable<UserNavigationItem[]>;
  public readonly username$: Observable<string>;
  public readonly impersonateeUsername$: Observable<string>;
  public readonly email$: Observable<string>;
  public readonly userId$: Observable<string>;
  public readonly signoutUrl$: Observable<string>;
  public readonly theme$: Observable<UITheme>;
  public readonly theming$: Observable<Theming>;
  public readonly language$: Observable<string>;
  public readonly notificationsEnabled$: Observable<boolean>;
  public readonly businessName$: Observable<string>;
  public readonly address$: Observable<string>;
  public readonly userSwitcherData$: Observable<UserSwitcherData[]>;
  public readonly partnerName$: Observable<string>;

  private readonly legacyNotificationPreferenceService = inject(LegacyNotificationPreferenceService);

  constructor(
    private apiService: AtlasApiService,
    private cfgService: AtlasConfigService,
    private languageService: LanguagesApiService,
  ) {
    this.navigationData$ = this.cfgService.config$.pipe(
      filter(({ partnerId }) => !!partnerId),
      switchMap((config: Config) =>
        this.apiService
          .getData(
            new GetDataRequest({
              partnerId: config.partnerId,
              marketId: config.marketId,
              groupPath: config.groupPath,
              accountGroupId: config.accountId,
              signOutNextUrl: '',
            }),
          )
          .pipe(
            filter((res: GetDataResponse) => JSON.stringify(res.toApiJson()) !== '{}'),
            map((res: GetDataResponse) => {
              const cache = {
                locationId: this.locationIdFromConfig(config),
                partnerId: config.partnerId,
                marketId: config.marketId,
                groupPath: config.groupPath,
                response: res,
              };
              return cache;
            }),
            tap((res) => this.rememberLastResponse(res)),
            startWith(this.getLastResponse()),
            filter(
              (res: CachedGetDataResponse) =>
                !!res &&
                res.locationId === this.locationIdFromConfig(config) &&
                res.partnerId === config.partnerId &&
                res.marketId === config.marketId,
            ),
            map((res: CachedGetDataResponse) => res.response),
          ),
      ),
      shareReplay(1),
    );

    this.centerData$ = this.navigationData$.pipe(
      map((res) => {
        if (!res.centers) {
          return [] as CenterNavigationItem[];
        }
        return res.centers;
      }),
    );

    this.userData$ = this.navigationData$.pipe(
      map((res) => {
        return res.user;
      }),
    );

    this.userSwitcherData$ = this.navigationData$.pipe(
      map((res) => {
        return res.userSwitcherData;
      }),
    );

    this.username$ = this.navigationData$.pipe(
      map((res) => {
        return res.username;
      }),
      distinctUntilChanged(),
    );

    this.partnerName$ = this.navigationData$.pipe(
      map((res) => {
        return res.partnerName;
      }),
    );

    this.impersonateeUsername$ = this.navigationData$.pipe(
      map((res) => {
        return res.impersonateeUsername;
      }),
      distinctUntilChanged(),
    );

    this.email$ = this.navigationData$.pipe(
      map((res) => {
        return res.email;
      }),
      distinctUntilChanged(),
    );

    this.userId$ = this.navigationData$.pipe(
      map((res) => {
        return res.userId;
      }),
      filter((userId) => !!userId),
      distinctUntilChanged(),
    );

    this.signoutUrl$ = this.navigationData$.pipe(
      map((res) => {
        return res.signOutUrl;
      }),
      distinctUntilChanged(),
    );

    // Checks if the user only has VBC. This is a special case for notifications because users who only use VBC will
    // need to have a check performed to see if their VBC notifications are turned off.
    const onlyVBCAccess$: Observable<boolean> = combineLatest([this.cfgService.config$, this.centerData$]).pipe(
      map(
        ([config, centerData]) =>
          !!config.accountId &&
          (centerData ? !centerData.find((center: CenterNavigationItem) => center.centerId !== 'VBC') : false),
      ),
      distinctUntilChanged(),
    );

    const vbcNotificationsEnabled$: Observable<boolean> = combineLatest([this.userId$, this.cfgService.config$]).pipe(
      switchMap(([userId, config]) => {
        return this.legacyNotificationPreferenceService.get$(config.partnerId, '', userId, config.accountId).pipe(
          catchError(() => {
            return of({ userNotificationsDisabled: true });
          }),
        );
      }),
      map((setting): boolean => !!setting && !setting.userNotificationsDisabled),
      distinctUntilChanged(),
      defaultIfEmpty(false),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const notifsEnabled$ = this.navigationData$.pipe(
      map((res) => !!res.notificationsEnabled),
      distinctUntilChanged(),
    );

    this.notificationsEnabled$ = combineLatest([notifsEnabled$, vbcNotificationsEnabled$, onlyVBCAccess$]).pipe(
      map(([dataNotificationsEnabled, vbcNotificationsEnabled, onlyVBCAccess]) => {
        if (!dataNotificationsEnabled) {
          return false;
        }
        if (onlyVBCAccess) {
          return vbcNotificationsEnabled;
        }
        return true;
      }),
    );

    this.businessName$ = this.navigationData$.pipe(
      map((res) => res.locationData.businessName),
      distinctUntilChanged(),
    );

    this.address$ = this.navigationData$.pipe(
      map((res) => res.locationData.address),
      distinctUntilChanged(),
    );

    this.theme$ = this.navigationData$.pipe(
      map((res) => res?.theme || UITheme.UI_THEME_DARK),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.theming$ = this.navigationData$.pipe(
      map((res) => {
        if (res?.theme === UITheme.UI_THEME_LIGHT) {
          // default to light theme
          return new Theming({
            primaryColor: '#fff',
            primaryHoverColor: '#f0f0f0',
            primaryActiveColor: '#e1e1e1',
            secondaryColor: 'rgba(0, 0, 0, .04)',
            secondaryHoverColor: 'rgba(0, 0, 0, .04)',
            secondaryActiveColor: 'rgba(0, 0, 0, .1)',
            fontColor: 'rgba(0, 0, 0, 0.54)',
            fontDisabledColor: 'rgba(0, 0, 0, 0.25)',
            accentsColor: '#000',
            accentsActiveColor: '#057ec1',
            focusColor: '#bbdefb',
            borderColor: '#dadada',
          });
        } else {
          // no response or dark theme is set
          return new Theming({
            primaryColor: '#212121',
            primaryHoverColor: '#303030',
            primaryActiveColor: '#3f3f3f',
            secondaryColor: 'rgba(255, 255, 255, .04)',
            secondaryHoverColor: 'rgba(255, 255, 255, .04)',
            secondaryActiveColor: 'rgba(255, 255, 255, .1)',
            fontColor: 'rgba(255, 255, 255, 0.7)',
            fontDisabledColor: 'rgba(255,255,255, 0.50)',
            accentsColor: '#fff',
            accentsActiveColor: '#057ec1',
            focusColor: '#616161',
            borderColor: '#9a9b9a',
          });
        }
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.language$ = this.navigationData$.pipe(
      map((res) => res.language || this.detectLanguageFromBrowser()),
      distinctUntilChanged(),
    );
  }

  setLanguage(language: string): Observable<HttpResponse<null>> {
    return this.languageService.setLanguage({ language }).pipe(
      tap(() => {
        const lastResponse = this.getLastResponse();
        if (lastResponse?.response) {
          lastResponse.response.language = language;
          this.rememberLastResponse(lastResponse);
        }
      }),
    );
  }

  private detectLanguageFromBrowser(): Language {
    const language = window.navigator.language;
    if (!language) {
      return Language.ENGLISH;
    }
    switch (language.toLowerCase()) {
      case Language.FRENCH:
      case Language.FRENCH_CANADA:
        return Language.FRENCH_CANADA;
      case Language.FRENCH_FRANCE:
        return Language.FRENCH_FRANCE;
      case Language.CZECH:
        return Language.CZECH;
      case Language.DUTCH:
        return Language.DUTCH;
      case Language.GERMAN:
        return Language.GERMAN;
      case Language.HEBREW:
        return Language.HEBREW;
      case Language.ITALIAN:
        return Language.ITALIAN;
      case Language.SPANISH_LATIN_AMERICA:
        return Language.SPANISH_LATIN_AMERICA;
      default:
        return Language.ENGLISH;
    }
  }

  private locationIdFromConfig(cfg: Config): string {
    return cfg.accountId || cfg.groupPath;
  }

  private rememberLastResponse(res: CachedGetDataResponse): void {
    localStorage.setItem(
      'atlas-last-response',
      JSON.stringify({
        locationId: res.locationId,
        partnerId: res.partnerId,
        marketId: res.marketId,
        response: res.response.toApiJson(),
      }),
    );
  }

  private getLastResponse(): CachedGetDataResponse {
    const o: { locationId: string; partnerId: string; marketId: string; response: object } = JSON.parse(
      localStorage.getItem('atlas-last-response'),
    );
    if (!o || !o.response) {
      return null;
    }
    return {
      locationId: o.locationId,
      partnerId: o.partnerId,
      marketId: o.marketId,
      response: GetDataResponse.fromProto(o.response),
    };
  }
}
